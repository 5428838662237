body {
  background-color: #000;
  user-select: none;
}

.App {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ColorShift {
  position: fixed;
  top: 50px;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, 0);
}

.ColorShift > label > input[type="checkbox"] {
  display: none;
}

.ColorShift > label > input[type="checkbox"] + .checkbox-display {
  position: relative;
  display: inline-block;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  background: #fff;
  height: 17px;
  width: 17px;
  border-radius: 5px;
  vertical-align: text-bottom;
  margin-right: 1em;
}

.ColorShift > label > input[type="checkbox"] + .checkbox-display::before {
  content: '\A0';
}

.ColorShift > label > input[type="checkbox"]:checked + .checkbox-display::before {
  content: '✔';
  position: absolute;
  top: -0.1em;
  left: -0.1em;
  font-size: 0.9em;
}

input:disabled {
  opacity: 0.5;
}

.ColorTemperature {
  position: fixed;
  top: 10px;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, 0);
  background-image: linear-gradient(
    to right,
    #ff6a00 0%,
    #f3ffff 50%,
    #b0ccff 100%
  );
}

.Brightness {
  position: fixed;
  bottom: 10px;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, 0);
  background-image: linear-gradient(to right, #000 0%, #fff 100%);
}

input[type="range"] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 50%;
  height: 5px;
  border-radius: 10px;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0px;
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -10px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #367ebd;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 0px;
  cursor: pointer;
  display: none;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: #fff;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #367ebd;
}
